import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Collapse,
  Flex,
  IconButton,
  Img,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { LevoMembership } from '@levo-so/membership';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useAppDispatch, useAppSelector } from '@/store';
import { logout } from '@/store/slices/userSlice';
import CustomButton from '../common/CustomButton';
import Logo from '../common/Logo';
import NavButton from '../common/NavButton';
import SectionContainer from '../common/SectionContainer';

interface INavbarProps {
  //   homeLink?: string;
  hideTopStrip?: boolean;
}

const navLinks = [
  {
    id: 1,
    text: 'About ',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'About IVCA ', link: '/about-us', subitems: [] },
      {
        title: 'Board of Directors',
        link: '/about-us/board-of-directors',
        subitems: [],
      },
      { title: 'IVCA Team ', link: '/about-us#our-team', subitems: [] },
      {
        title: 'IVCA Code of Ethics',
        link: '/ivca-code-of-ethics',
        // link: 'https://space.levo.so/WGLBUT82/IVCA-CodeofEthics-ForAIFMembers-Copy-Ncz0XHsJ2z0e.pdf',
        subitems: [],
      },
      {
        title: 'IVCA: Brief Profile',
        link: 'https://space.levo.so/WGLBUT82/AboutIVCANote12thseptember-SvIjsFLUaZ7Acompressed-rSCrlHHI6L1t.pdf',
        subitems: [],
      },
    ],
  },
  {
    id: 2,
    text: 'Committee',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'IVCA Executive Committee', link: '/committee', subitems: [] },
      {
        title: 'Councils',
        link: '/committee/council',
        subitems: [],
      },
      {
        title: 'Special Interest Groups',
        link: '/committee/sig',
        subitems: [],
      },
    ],
  },
  {
    id: 3,
    text: 'Advocacy',
    type: 'dropdown',
    link: '#',
    items: [
      {
        title: 'Advocacy at IVCA',
        link: '/advocacy-intro',
        subitems: [],
      },
      {
        title: 'Representations (Docsend Repository)',
        link: '/resources/year-wise-representation',
        subitems: [],
      },
      {
        title: 'Regulatory Updates',
        subitems: [
          {
            title: 'SEBI',
            link: '/sebiupdates',
            subitemsList: [
              //   {
              //     title: 'NISM Certification',
              //     link: '/resources/nism-certification',
              //   },
              //   {
              //     title: 'Performance Benchmarking',
              //     link: '/resources/performance-aifs',
              //   },
            ],
          },
          {
            title: 'IFSCA',
            link: '/ifsca',
          },
          {
            title: 'IRDAI',
            link: '/irdai',
          },
          {
            title: 'CBIC',
            link: '/cbic',
          },
        ],
      },
      //   {
      //     title: 'Performance Benchmarking of AIFs',
      //     link: '/resources/performance-aifs',
      //     subitems: [],
      //   },
      //   {
      //     title: 'Certification by NISM',
      //     link: '/resources/nism-certification',
      //     subitems: [],
      //   },
      //   {
      //     title: 'Valuation Guidelines',
      //     link: '/resources/valuation-guidelines',
      //     subitems: [],
      //   },
    ],
  },
  {
    id: 4,
    text: 'Membership',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'Our Members', link: '/membership', subitems: [] },
      {
        title: 'Member Benefits',
        link: '/membership/member-benefits',
        subitems: [],
      },
      {
        title: 'Membership Form',
        link: '/membership/membership-form',
        subitems: [],
      },
    ],
  },
  {
    id: 5,
    text: 'Resources',
    type: 'dropdown',
    link: '',
    items: [
      {
        title: 'IVCA Publication',
        link: '',
        subitems: [
          { title: 'Recent Reports', link: '/resources/recent-reports' },
          { title: 'IVCA PE/VC DealTracker', link: '/resources/deal-tracker' },
          { title: 'Ripe Magazine', link: '/insights/ripe-magazine' },
        ],
      },
      {
        title: 'Knowledge Partner Publication',
        link: '/resources/partner-publication',
        subitems: [],
      },
      {
        title: 'Union Budget 2024 Knowledge Partner Reports',

        link: '/budget-partner-publications',
        subitems: [],
      },
      {
        title: '#VC101',
        link: '/vc-101',
        subitems: [],
      },
    ],
  },
  {
    id: 6,
    text: 'Initiatives',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'Upcoming Initiatives', link: '/initiatives', subitems: [] },
      {
        title: 'Maximum India Conclave',
        link: 'https://maximumindiaconclave.in/',
        subitems: [],
        external: true,
      },
      {
        title: 'Past Initiatives',
        link: '/initiatives/past-initiatives',
        subitems: [],
      },
      {
        title: 'IVCA Conclave',
        link: 'https://ivcaconclave.com/',
        subitems: [],
      },
      { title: 'IVCA PE/VC Awards', link: '/awards', subitems: [] },
      {
        title: '#VC101',
        link: '/vc-101',
        subitems: [],
      },
      {
        title: '#ALTCAP101',
        link: '/altcap-101',
        subitems: [],
      },
    ],
  },
  {
    id: 7,
    text: 'Newsroom',
    type: 'dropdown',
    link: '#',
    items: [
      {
        title: 'Newsletter',
        link: '/newsletter',
        subitems: [],
      },
      { title: 'Press Release', link: '/press-release', subitems: [] },
      { title: 'Blog', link: '/insights/blogs', subitems: [] },
      { title: 'Press Kit', link: '/presskit', subitems: [] },
      { title: 'IVCA in News', link: '/ivca-in-news', subitems: [] },
      //   { title: 'IVCA Newsletter', link: '/newsletter', subitems: [] },
    ],
  },
  {
    id: 8,
    text: 'Industry Job Board',
    type: 'single',
    link: '/jobs',
    items: [],
  },
];
const navLinksWMember = [
  {
    id: 1,
    text: 'About ',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'About IVCA ', link: '/about-us', subitems: [] },
      {
        title: 'Board of Directors',
        link: '/about-us/board-of-directors',
        subitems: [],
      },
      { title: 'IVCA Team ', link: '/about-us#our-team', subitems: [] },
      {
        title: 'IVCA: A Detailed Profile',
        link: '/about-us#ivca-detailed-profile',
        subitems: [],
      },
    ],
  },
  {
    id: 2,
    text: 'Committee',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'IVCA Executive Committee', link: '/committee', subitems: [] },
      {
        title: 'Councils',
        link: '/committee/council',
        subitems: [],
      },
      {
        title: 'Special Interest Groups',
        link: '/committee/sig',
        subitems: [],
      },
    ],
  },

  {
    id: 3,
    text: 'Membership',
    type: 'dropdown',
    link: '#',
    items: [
      { title: 'Our Members', link: '/membership', subitems: [] },
      {
        title: 'Member Benefits',
        link: '/membership/member-benefits',
        subitems: [],
      },
    ],
  },

  {
    id: 4,
    text: 'Resources',
    type: 'dropdown',
    link: '',
    items: [
      {
        title: 'Regulators',
        subitems: [
          {
            title: 'SEBI',
            link: '/regulatory-reports-sebi-circular',
          },
        ],
      },
      {
        title: 'Advocacy',
        subitems: [
          {
            title: 'Advocacy at IVCA',
            link: '/advocacy-intro',
          },
          {
            title: 'Year Wise Representations',
            link: '/resources/year-wise-representation',
          },
          { title: 'Monthly Advocacy update', link: '/resources' },

          {
            title: 'IVCA Regulatory Webinars',
            link: '#',
            subitemsList: [
              {
                title: 'IVCA Regulatory Webinar August 2024',
                link: '/regulatory-webinar-august',
              },
            ],
          },
        ],
      },

      {
        title: 'IVCA Publication',
        link: '',
        subitems: [
          { title: 'Recent Reports', link: '/resources/recent-reports' },
          { title: 'IVCA PE/VC DealTracker', link: '/resources/deal-tracker' },
          { title: 'Ripe Magazine', link: '/insights/ripe-magazine' },
        ],
      },

      {
        title: 'Knowledge Partner Publication',
        link: '/resources/partner-publication',
        subitems: [],
      },

      //   {
      //     title: 'Performance Benchmarking of AIFs',
      //     link: '/resources/performance-aifs',
      //     subitems: [],
      //   },
      {
        title: 'NISM Certification Course',
        link: 'https://www.nism.ac.in/nism-series-xix-a-alternative-investment-funds-category-i-and-ii-distributors/',
        subitems: [],
      },
      //   {
      //     title: 'Valuation Guidelines',
      //     link: '/resources/valuation-guidelines',
      //     subitems: [],
      //   },
      {
        title: '#VC101',
        link: '/vc-101',
        subitems: [],
      },
    ],
  },
  {
    id: 5,
    text: 'Events',
    type: 'dropdown',
    link: '#',
    items: [
      //   { title: 'Upcoming Events', link: '/events', subitems: [] },
      { title: 'Upcoming Initiatives', link: '/initiatives', subitems: [] },
      {
        title: 'Maximum India Conclave',
        link: 'https://maximumindiaconclave.in/',
        subitems: [],
        external: true,
      },
      { title: 'Past Events', link: '/events/past-events', subitems: [] },
      {
        title: 'IVCA Conclave',
        link: 'https://ivcaconclave.com/',
        subitems: [],
      },
      { title: 'IVCA PE/VC Awards', link: '/awards', subitems: [] },
      {
        title: '#VC101',
        link: '/vc-101',
        subitems: [],
      },
    ],
  },
  {
    id: 6,
    text: 'Newsroom',
    type: 'dropdown',
    link: '#',
    items: [
      {
        title: 'Newsletter',
        subitems: [
          { title: '2023', link: '/newsletter/capital-call-newsletter' },
        ],
      },
      { title: 'Press Release', link: '/press-release', subitems: [] },
      { title: 'Blog', link: '/insights/blogs', subitems: [] },
      { title: 'Press Kit', link: '/presskit', subitems: [] },
      { title: 'IVCA in News', link: '/ivca-in-news', subitems: [] },
    ],
  },
  {
    id: 8,
    text: 'Industry Job Board',
    type: 'single',
    link: '/jobs',
    items: [],
  },
  {
    id: 9,
    text: 'Post Job',
    type: 'single',
    link: '/job-board/post-job',
    items: [],
  },
];

interface INavbar {
  id: number;
  text: string;
  link: string;
  type: string;
  items: {
    title: string;
    link: string;
    subitems: {
      title?: string;
      link?: string;
      subitemsList?: {
        title?: string;
        link?: string;
      }[];
    }[];
  }[];
}

const Navbar: React.FunctionComponent<INavbarProps> = ({
  hideTopStrip = true,
}) => {
  const router = useRouter();

  const outsideClickRef = useRef(null);
  const isLoggedIn = useAppSelector((state) => state.user?.isLoggedIn);
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState<{
    type: string;
    text?: string;
    link?: string;
    items: {
      title: string;
      link?: string;
      external?: boolean;
      subitems?: {
        title?: string;
        link?: string;
        subitemsList?: { title?: string; link?: string }[];
      }[];
    }[];
  }>({
    type: 'none',
    text: '',
    items: [],
    link: '',
  });
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const userState = useAppSelector((state) => state.user);
  const closeMenu = () =>
    setMenuOpen({ type: 'none', text: '', items: [], link: '' });

  useOutsideClick({
    ref: outsideClickRef,
    handler: closeMenu,
  });

  // @ts-ignore
  const effectiveLinks: INavbar[] =
    router.pathname === '/vc-101'
      ? [
          {
            id: 1,
            text: 'About ',
            type: 'single',
            link: '#about',
            items: [],
          },
          {
            id: 2,
            text: 'Program Details',
            type: 'single',
            link: '#program-details',
            items: [],
          },
          {
            id: 3,
            text: 'Mentors',
            type: 'single',
            link: '#mentors',
            items: [],
          },
          {
            id: 5,
            text: 'FAQ',
            type: 'single',
            link: '#faq',
            items: [],
          },
          {
            id: 6,
            text: 'Newsroom',
            type: 'dropdown',
            link: '#',
            items: [
              { title: 'News', link: '#media-coverage', subitems: [] },
              { title: 'Blogs', link: '#blogs', subitems: [] },
            ],
          },
        ]
      : router.pathname === '/corporate-governance'
      ? [
          {
            id: 1,
            text: 'About',
            type: 'dropdown',
            link: '#',
            items: [
              {
                title: 'IVCA Corporate Governance',
                link: '#info',
                subitems: [],
              },
              { title: 'About IVCA', link: '/about-us', subitems: [] },
            ],
          },
          {
            id: 2,
            text: 'Initiatives',
            type: 'dropdown',
            link: '#initiatives',
            items: [
              {
                title: 'Past Initiatives',
                link: '#past-initiatives',
                subitems: [],
              },
              {
                title: 'Upcoming Initiatives',
                link: '#upcoming-initiatives',
                subitems: [],
              },
            ],
          },
          {
            id: 4,
            text: 'Resources',
            type: 'dropdown',
            link: '#',
            items: [
              {
                title: 'Latest Links',
                link: '#',
                subitems: [],
              },
              {
                title: 'Training Programs',
                link: '#',
                subitems: [],
              },
            ],
          },
          {
            id: 5,
            text: 'Newsroom',
            type: 'dropdown',
            link: '#',
            items: [
              { title: 'Blog', link: '/insights/blogs', subitems: [] },
              { title: 'IVCA in News', link: '/ivca-in-news', subitems: [] },
            ],
          },
        ]
      : router.route === '/green-future'
      ? [
          {
            id: 1,
            text: 'About',
            type: 'single',
            link: '#about',
            items: [],
          },
          {
            id: 2,
            text: 'Climate Trends',
            type: 'single',
            link: '#climate-trends',
            items: [],
          },
          {
            id: 3,
            text: 'Climate & Sustainability Summit',
            type: 'single',
            link: '#climate-summit',
            items: [],
          },
          {
            id: 4,
            text: 'Webinars',
            type: 'single',
            link: '#webinars',
            items: [],
          },
          {
            id: 5,
            text: 'Resources',
            type: 'single',
            link: '#resources',
            items: [],
          },
          {
            id: 6,
            text: 'Contact',
            type: 'single',
            link: '#contacts',
            items: [],
          },
        ]
      : isLoggedIn
      ? navLinksWMember
      : navLinks;

  return (
    <Box
      as="nav"
      width="100%"
      //   h={{ xs: '100px', md: '123px' }}
      h={{
        xs: hideTopStrip ? '100px' : '136px',
        md: hideTopStrip ? '123px' : '164px',
      }}
      bg="white"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="50"
      borderBottom="1px solid rgba(0, 0, 0, 0.09)"
    >
      {/* {hideTopStrip && (
        <Link href="/sebiupdates">
          <Flex
            h="36px"
            justify="center"
            align="center"
            w="full"
            bgColor="#e6ffb6"
            className="group"
            transform="auto"
            transition="all 0.2s ease-in"
            position="absolute"
          >
            <Heading
              cursor="pointer"
              as="h1"
              color="#415023"
              _groupHover={{
                color: 'secondaryPurple.900',
              }}
              fontSize={{ xs: '11.5px', md: '13px' }}
              variant="queryContact"
              transform="auto"
              transition="all 0.2s ease-in"
            >
              Update on SEBI Circulars
              (NEW) Regulatory Reporting by AIFs - Circular Issued by SEBI
            </Heading>
          </Flex>
        </Link>
      )} */}
      {/* <SectionContainer height="100%"> */}
      <SectionContainer
        pt={{
          xs: hideTopStrip ? '0' : '16px',
          md: hideTopStrip ? '0px' : '0px',
        }}
        height="100%"
      >
        <Flex h="100%" w="100%" justify="space-between" alignItems="center">
          <Logo homeLink={router.pathname === '/vc-101' ? '/vc-101' : '/'} />
          <Menu>
            <MenuButton
              aria-label="Menu Button"
              display={{ xs: 'block', lg: 'none' }}
              bgColor="transparent"
              _hover={{ bgColor: 'transparent' }}
              _active={{ bgColor: 'transparent' }}
              color="text.mediumBlackAlpha"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <GiHamburgerMenu size="40px" />
            </MenuButton>
            <MenuList width="100vw">
              <Accordion allowMultiple>
                {effectiveLinks.map((nav) => (
                  <AccordionItem border="none" key={nav.id} id={nav.text}>
                    {nav.type === 'single' ? (
                      <Link href={nav.link}>
                        <AccordionButton pb="0">
                          <Box
                            flex="1"
                            textAlign="left"
                            fontWeight="700"
                            _hover={{ color: 'primary.500' }}
                          >
                            {nav.text}
                          </Box>
                        </AccordionButton>
                      </Link>
                    ) : (
                      <>
                        <AccordionButton pb="0">
                          <Box
                            flex="1"
                            textAlign="left"
                            fontWeight="700"
                            _hover={{ color: 'primary.500' }}
                          >
                            {nav.text}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pl="30px" py="0" itemID="nav-links">
                          {nav.items.map((item, id) => (
                            <React.Fragment key={item.title}>
                              {!item.subitems.length ? (
                                <Link href={item.link ?? '#'}>
                                  <Box
                                    flex="1"
                                    textAlign="left"
                                    fontWeight="600"
                                    _hover={{ color: 'primary.500' }}
                                  >
                                    {item.title}
                                  </Box>
                                </Link>
                              ) : (
                                <Accordion allowMultiple>
                                  <AccordionItem
                                    border="none"
                                    id={item.subitems[id]?.title}
                                  >
                                    <AccordionButton px="0" py="2px">
                                      <Box
                                        flex="1"
                                        textAlign="left"
                                        fontWeight="600"
                                        _hover={{ color: 'primary.500' }}
                                      >
                                        {item.title}
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel py="0">
                                      {item.subitems.map((subitem) => (
                                        <>
                                          <Link
                                            key={subitem.title}
                                            href={subitem.link || '/'}
                                            passHref
                                          >
                                            <Text
                                              id={subitem.title}
                                              textAlign="left"
                                              fontWeight="400"
                                              _hover={{ color: 'primary.500' }}
                                              cursor="pointer"
                                            >
                                              {subitem.title}
                                            </Text>
                                          </Link>
                                          {subitem?.subitemsList &&
                                            subitem?.subitemsList?.length >
                                              0 && (
                                              <Box pl="8px">
                                                {subitem?.subitemsList?.map(
                                                  (sub) => (
                                                    <Link
                                                      key={sub.title}
                                                      href={sub.link || '/'}
                                                      passHref
                                                    >
                                                      <Text
                                                        id={sub.title}
                                                        textAlign="left"
                                                        fontWeight="400"
                                                        _hover={{
                                                          color: 'primary.500',
                                                        }}
                                                        cursor="pointer"
                                                      >
                                                        {sub.title}
                                                      </Text>
                                                    </Link>
                                                  )
                                                )}
                                              </Box>
                                            )}
                                        </>
                                      ))}
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                              )}
                            </React.Fragment>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
              <Flex
                flexDir="column"
                rowGap="10px"
                pt="10px"
                pl="15px"
                pr="20px"
              >
                {isLoggedIn ? (
                  <Box>
                    <Text
                      color="text.textViolet"
                      fontSize="14px"
                      fontWeight="700"
                      mb="0.5rem"
                      lineHeight="21px"
                    >
                      Hi,{' '}
                      {userState.first_name
                        ? userState.first_name
                        : userState.username}
                    </Text>
                    <Flex width="100%" justify="space-between">
                      <NavButton
                        onClick={() => {
                          dispatch(logout());
                          LevoMembership.signOut().catch((e) => {
                            console.error(e);
                          });
                        }}
                        text="Logout"
                        variant="outline"
                        isLogout
                      />
                      <NavButton
                        text="Contact Us"
                        link="/contact"
                        variant="outline"
                      />
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <NavButton
                      text="Contact Us"
                      link="/contact"
                      variant="outline"
                    />
                    {router.route !== '/vc-101' && (
                      <>
                        <NavButton
                          text="Member Login"
                          variant="outline"
                          isLogin
                        />
                        <CustomButton
                          text="Become a Member"
                          colorScheme="primary"
                          icon
                          link="/membership/membership-form"
                        />
                      </>
                    )}
                  </>
                )}
              </Flex>
            </MenuList>
          </Menu>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <Flex justifyContent="flex-end">
              <Flex
                columnGap="5px"
                // pt="13px"
                pb="15px"
                justify="flex-end"
                alignItems="center"
              >
                {/* <Link href="https://www.facebook.com/indiavca/" target="_blank">
                  <IconButton
                    aria-label="facebook"
                    icon={
                      <FaFacebook
                        size="22px"
                        aria-label="Facebook icon"
                        color="rgba(100, 100, 140, 0.6)"
                      />
                    }
                    variant="unstyled"
                    cursor="pointer"
                  />
                </Link> */}
                <Link
                  href="https://in.linkedin.com/company/indian-private-equity-venture-capital-association"
                  target="_blank"
                  aria-label="LinkedIn IVCA"
                >
                  <IconButton
                    aria-label="linkedin"
                    icon={
                      <FaLinkedin
                        aria-label="Linkedin icon"
                        size="22px"
                        color="rgba(100, 100, 140, 0.6)"
                      />
                    }
                    variant="unstyled"
                    cursor="pointer"
                  />
                </Link>

                <Link
                  href="https://twitter.com/IndianVCA"
                  target="_blank"
                  aria-label="twitter IVCA"
                >
                  <Img
                    src="https://space.levo.so/WGLBUT82/7132271116957757172.svg"
                    alt="twitter"
                    mr={4}
                  />
                </Link>
                <Link
                  href="https://www.youtube.com/channel/UCEyV1l7ncz8m9xYVXlhGEeA"
                  target="_blank"
                  aria-label="youtube IVCA"
                >
                  <IconButton
                    aria-label="youtube icon"
                    icon={
                      <FaYoutube size="22px" color="rgba(100, 100, 140, 0.6)" />
                    }
                    variant="unstyled"
                    cursor="pointer"
                  />
                </Link>
              </Flex>
              <Flex
                columnGap="5px"
                // pt="13px"
                pb="15px"
                // w="fit-content"
                justifyContent={isLoggedIn ? 'flex-end' : 'flex-end'}
                alignItems="center"
                gap="16px"
                flex={router.route !== '/vc-101' ? 1 : 0}
              >
                <NavButton text="Contact Us" link="/contact" variant="ghost" />
                {isLoggedIn ? (
                  <Flex ml="2rem" align="center">
                    <Text
                      color="text.textViolet"
                      fontSize="14px"
                      fontWeight="700"
                      mr="0.75rem"
                      lineHeight="21px"
                    >
                      Hi,{' '}
                      {userState.first_name
                        ? userState.first_name
                        : userState.username}
                    </Text>
                    <IconButton
                      aria-label="Log Out"
                      icon={<FiLogOut size="1.3rem" color="#3D3D5FC7" />}
                      bg="transparent"
                      title="Log Out"
                      onClick={() => {
                        dispatch(logout());
                        LevoMembership.signOut().catch((e) => {
                          console.error(e);
                        });
                      }}
                    />
                  </Flex>
                ) : router.route !== '/vc-101' ? (
                  <>
                    <NavButton text="Member Login" variant="outline" isLogin />
                    <CustomButton
                      text="Become a Member"
                      colorScheme="primary"
                      link="/membership/membership-form"
                      icon
                    />
                  </>
                ) : null}
              </Flex>
            </Flex>
            <Flex pb="10px" justifyContent="flex-end">
              {effectiveLinks.map((item, i) => (
                <Box key={item.text} position="relative" ml={i ? '40px' : '0'}>
                  {item.type === 'single' ? (
                    <Link href={item.link || '/'}>
                      <Text
                        aria-label={item.text}
                        _hover={{ textDecoration: 'none' }}
                        fontWeight="900"
                        color={
                          menuOpen.text === item.text
                            ? 'primary.500'
                            : 'text.mediumBlack'
                        }
                        fontSize="14px"
                      >
                        {item.text}
                      </Text>
                    </Link>
                  ) : (
                    <Flex
                      color={
                        router.pathname || menuOpen.text === item.text
                          ? 'primary.500'
                          : 'textBlack'
                      }
                      onClick={() => {
                        if (item.type === 'dropdown') {
                          setMenuOpen(item);
                        } else {
                          setMenuOpen({ type: 'none', items: [], text: '' });
                        }
                      }}
                      cursor="pointer"
                      borderBottomStyle={
                        router.pathname === item.text ? 'solid' : 'none'
                      }
                    >
                      <Text
                        mr="4px"
                        fontWeight="900"
                        fontSize="14px"
                        _hover={{ color: 'primary.500' }}
                        _active={{ color: 'primary.500' }}
                        color={
                          item.text === menuOpen.text
                            ? 'primary.500'
                            : '#3D3D5F'
                        }
                      >
                        {item.text}
                      </Text>
                      {item.text === menuOpen.text ? (
                        <Image
                          src="/svg/chevronUp.svg"
                          alt="Up arrow"
                          width={17}
                          height={21}
                        />
                      ) : (
                        <Image
                          src="/svg/chevronDown.svg"
                          alt="Down arrow"
                          width={17}
                          height={21}
                        />
                      )}
                    </Flex>
                  )}
                  <Collapse
                    unmountOnExit
                    in={
                      menuOpen.type === 'dropdown' &&
                      item.text === menuOpen.text
                    }
                  >
                    <Box
                      width="max-content"
                      borderBottomRadius="5px"
                      position="absolute"
                      top="30px"
                      minWidth="6.5rem"
                      left="-10px"
                      bg="white"
                      boxShadow="md"
                    >
                      {menuOpen.items?.map(
                        ({
                          title = '',
                          link = '',
                          subitems = [],
                          external = false,
                        }) => (
                          <Link
                            id={title}
                            passHref
                            key={title}
                            href={link}
                            target={external ? '_blank' : '_self'}
                          >
                            <Box
                              onClick={() => {
                                if (link) {
                                  setMenuOpen({
                                    type: 'none',
                                    items: [],
                                    text: '',
                                  });
                                }
                              }}
                              _hover={{ color: 'primary.500' }}
                              _active={{ color: 'primary.500' }}
                              cursor="pointer"
                              p="16px"
                              pb={subitems.length ? '0px' : '8px'}
                              fontWeight="600"
                              fontSize="0.875rem"
                            >
                              {title}
                              {subitems?.map((subitem) => (
                                <>
                                  <Link
                                    passHref
                                    id={subitem.title}
                                    aria-label={subitem.title}
                                    href={subitem.link || '#'}
                                    key={subitem.title}
                                    target={external ? '_blank' : '_self'}
                                  >
                                    <Text
                                      onClick={() => {
                                        if (link) {
                                          setMenuOpen({
                                            type: 'none',
                                            items: [],
                                            text: '',
                                          });
                                        }
                                      }}
                                      _hover={{ color: 'primary.500' }}
                                      cursor="pointer"
                                      px="16px"
                                      py="6.4px"
                                      fontWeight="600"
                                      fontSize="0.875rem"
                                    >
                                      {subitem.title}
                                    </Text>
                                  </Link>
                                  {subitem?.subitemsList &&
                                    subitem?.subitemsList?.length > 0 && (
                                      <Box>
                                        {subitem?.subitemsList?.map((sub) => (
                                          <Link
                                            passHref
                                            id={sub.title}
                                            aria-label={sub.title}
                                            href={sub.link as string}
                                            key={sub.title}
                                            target={
                                              external ? '_blank' : '_self'
                                            }
                                          >
                                            <Text
                                              onClick={() =>
                                                router.push(sub.link as string)
                                              }
                                              //   onClick={() => {
                                              //     if (sub.link) {
                                              //       setMenuOpen({
                                              //         type: 'none',
                                              //         items: [],
                                              //         text: '',
                                              //       });
                                              //     }
                                              //   }}
                                              _hover={{ color: 'primary.500' }}
                                              cursor="pointer"
                                              px="24px"
                                              py="6.4px"
                                              fontWeight="600"
                                              fontSize="0.875rem"
                                            >
                                              {sub.title}
                                            </Text>
                                          </Link>
                                        ))}
                                      </Box>
                                    )}
                                </>
                              ))}
                            </Box>
                          </Link>
                        )
                      )}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
      </SectionContainer>
    </Box>
  );
};

export default Navbar;
